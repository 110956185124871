<template>
  <el-container>
    <div class="w-100">
      <div class="page-head flex space-between">
        <el-button type="text" icon="iconfont icon-pinglun">返回</el-button>
        <ul>
          <li><i class="iconfont icon-shanchu"></i></li>
          <li><i class="iconfont icon-shanchu"></i></li>
        </ul>
      </div>
      <el-card class="box-card mod-warpper">
        <el-breadcrumb-item separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>新建方案</el-breadcrumb-item>
          <el-breadcrumb-item>关键词设置</el-breadcrumb-item>
        </el-breadcrumb-item>
        <!-- 专家模式、普通模式 -->
        <div class="pattern">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="专家模式" name="first">
              <div class="zjms">
                <p class="pattern-name">方案名称</p>
                <el-input
                  type="text"
                  placeholder="请设置方案名称"
                  v-model="text"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane label="普通模式" name="second">配置管理</el-tab-pane>
          </el-tabs>
        </div>
        <!--@end 专家模式、普通模式 -->
      </el-card>
    </div>
  </el-container>
</template>
<script>
export default {
  name: 'plan-new',
  data() {
    return {
      activeName: 'first',
      text: ''
    };
  },
  methods: {
    handleClick(tab, event) {}
  }
};
</script>
<style scoped>
/*  */
.page-head {
  height: 54px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px 0px rgba(223, 222, 222, 0.5);
  width: 100%;
  padding: 0 40px;
  font-size: 14px;
  line-height: 54px;
}
.page-head .el-button {
  font-size: 14px;
  color: #414141;
}
.page-head .iconfont,
.page-head >>> .el-button span {
  margin-left: 10px;
}
.page-head li {
  float: right;
}
/* 专家模式、普通模式 */
.pattern-name {
  line-height: 40px;
  font-size: 14px;
}
</style>
